import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';
import { isString } from 'common/types';

import SearchLocalizationForm, {
  RouteProps
} from 'website/components/ui/SearchLocalizationForm';

export default function searchLocationAutocomplete() {
  const positions = document.getElementsByClassName('js-search-location');

  if (positions.length) {
    for (const position of positions) {
      const route = readAttribute<RouteProps, undefined>(
        position,
        'data-route'
      );

      if (route && !isString(route)) {
        const placeholder = readAttribute<string, undefined>(
          position,
          'data-placeholder'
        );
        const root = createRoot(position);
        root.render(
          <SearchLocalizationForm route={route} placeholder={placeholder} />
        );
      }
    }
  }
}
